import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { getFlightInfo, createReport, getReport, ReportAPIParams } from '../../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../../provider/AuthProvider';
import { FilightFilterSelector, FlightFilters } from '../flight-filter-selector.component';
import ReportForm from './report-form.component';
import UserSchedules from './user-schedules.component';

const Styles = styled.div`
    text-align:center;
    max-height: 70vh;
    overflow-y: scroll;
`;

interface UserReportProps {}

export default function UserReport(props: UserReportProps) {
    let [flight, setFlight] = useState<string | null>(null);
    let { user } = useAuth() as AuthContextType;
    let [schedules, setSchedules] = useState<any[]>([]);
    let [selectedSchedule, setSelectedSchedule] = useState<ReportAPIParams>({});
    let [report, setReport] = useState<any>({});

    function transformReportToSchedule(report: any) {
        return {
            dep_iata: report.dep_airport,
            dep_time: report.flight_time,
            airline_iata: report.airline,
            dep_terminal: report.terminal,
            checked: false
        }
    }

    function loadReport(params: ReportAPIParams, flight: string | null, filters: FlightFilters | null) {
        getReport(user.user.dbUser.appToken, params)
            .then((reports) => {
                if (!flight) {
                    if (reports && reports.length) {
                        let schedules = reports.map(transformReportToSchedule);
                        schedules[0].checked = true;
                        setSelectedSchedule({dep_airport: schedules[0].dep_iata, flight_time: schedules[0].dep_time})
                        setSchedules(schedules);
                    } else {
                        if (filters) {
                            setSelectedSchedule({
                                dep_airport: filters.airport,
                                flight_time: filters.time?.getTime(),
                                dep_terminal: filters.terminal
                            });
                        }
                        setSchedules([]);
                    }
                }
                setReport(reports[0] || {})
            })
            .catch(console.error);
    }

    function updateSelectedFlight(flightRow: any) {
        schedules = schedules.map(r => {
            r.checked = flightRow.id === r.id;
            return r;
        })
        let selected: any = schedules.find(r => r.checked);
        loadReport({ flight_id: selected.id }, selected.id, null);
        setSchedules(schedules);
    }


    async function onFlightSubmit() {
        if (flight) {
            let flightInfo: any[] = await getFlightInfo(user.user.dbUser.appToken, flight);
            flightInfo.forEach(f => f.checked = false);
            if (flightInfo.length) {
                flightInfo[0].checked = true;
                setSelectedSchedule({flight_id: flightInfo[0].id})
                loadReport({ flight_id: flightInfo[0].id }, flight, null);
            }
            setSchedules(flightInfo);
        }
    }

    function reset() {
        setSchedules([]);
        setSelectedSchedule({});
    }



    function onReportSubmit() {
        let report_sections = report.report_sections;
        createReport(user.user.dbUser.appToken, report_sections, selectedSchedule);
    }

    function onReportSectionsUpdate(reportSections: any) {
        let newReport: any = report || {};
        newReport.report_sections = reportSections;
        setReport(report);
    }

    async function onFilterUpdate(filters: FlightFilters) {
        if (!filters.time) {
            return;
        }
        loadReport({
            dep_airport: filters.airport,
            flight_time: filters.time.getTime()
        }, null, filters);
    }

    return (
        <div className="narrow-page-wrapper">
            <div className="narrow-page-inner">
                <Styles>
                    <h3>Report your time</h3>
                    <label>Search by: </label>
                    <Tabs
                        defaultActiveKey="flight"
                        id="auth-methods-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="flight" title="Flight">
                            <div className="mb-3">
                                <label>Flight</label>
                                <input
                                    className="form-control"
                                    placeholder="Flight number"
                                    id="flight"
                                    autoComplete="none"
                                    onChange={e => setFlight(e.target.value)}
                                />
                            </div>
                            <div className="d-grid">
                                <button className="btn btn-primary" onClick={onFlightSubmit}>
                                    Search
                                </button>
                            </div>
                        </Tab>
                        <Tab eventKey="airport" title="Airport">
                            <div className="mb-3">
                                <label>Airport</label>
                                <FilightFilterSelector
                                    onSelectionUpdate={onFilterUpdate}
                                />
                            </div>
                        </Tab>
                    </Tabs>
                    <div>
                        {
                            schedules.length > 0 &&
                            <UserSchedules
                                onSelectionUpdate={updateSelectedFlight}
                                schedules={schedules}
                            />
                        }
                        {
                            Object.keys(selectedSchedule).length > 0 &&
                            <>
                                <ReportForm
                                    onReportSectionsUpdate={onReportSectionsUpdate}
                                    reportParams={selectedSchedule}
                                    defaultReportSections={((report || {}) as any).report_sections}
                                />
                                <button className="btn btn-primary" onClick={onReportSubmit}>
                                    Submit
                                </button>
                                <span>&nbsp;</span>
                                <button className="btn btn-primary" onClick={reset}>
                                    Reset
                                </button>
                            </>
                        }
                    </div>
                </Styles>
            </div>
        </div>
    );
}