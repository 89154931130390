import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { getLocation } from "../../api/api-wrapper";
import DateTimePicker from "../common/date-time-picker";
import { TerminalSelector } from "../common/terminal-selector.component";
import AirportSelector from "./airports.component";
import CountrySelector from "./countries.component";

const ParentDiv = styled.div`
  & {
    width: 100%;
  }
  @media(max-width: 600px) {
    min-height:12rem;
    display: grid;
  }
  @media(max-width: 400px) {
    min-height:8rem;
    display: grid;
  }
`;

const ChildDiv = styled.div`
  & {
    display: inline-block;
    vertical-align: text-top;
    margin: 0 auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
`;

export interface FlightFilters {
    country?: string;
    airport?: string;
    terminal?: string;
    time?: Date;
}

interface FilightFilterSelectorProps {
    onSelectionUpdate: Function
}

export function FilightFilterSelector({ onSelectionUpdate }: FilightFilterSelectorProps) {
    let [flightFilters, setFlightFiltes] = useState<FlightFilters>({});
    let [country, setCountry] = useState<string>();
    let [airport, setAirport] = useState<string | null>();
    useEffect(() => {
        async function loadDefaultCountry() {
            if (!country) {
                let defaultCountry = await getLocation();
                flightFilters.country = defaultCountry.alpha_2;
                setCountry(defaultCountry.alpha_2);
                setFlightFiltes(flightFilters);
                setAirport(null);
            }
        }
        loadDefaultCountry();
    }, [airport, country, flightFilters])
    function updateCountry(country: string) {
        flightFilters.country = country;
        setCountry(country);
        setFlightFiltes(flightFilters);
    }
    function updateAirport(airport: string) {
        flightFilters.airport = airport;
        setAirport(airport);
        setFlightFiltes(flightFilters);
    }
    function updateTerminal(terminal: string) {
        flightFilters.terminal = terminal;
        setFlightFiltes(flightFilters);
    }
    function onTimeSelected(time: Date) {
        flightFilters.time = time;
        setFlightFiltes(flightFilters);
        onSelectionUpdate(flightFilters);
    }
    function getStartDate() {
        return new Date();
    }
    function getEndDate() {
        let now = new Date();
        now.setDate(now.getDate() + 5);
        return now;
    }
    return (
        <ParentDiv>
            <ChildDiv>
                <CountrySelector
                    defaultCountry={country as string}
                    onSelectionUpdate={updateCountry}
                ></CountrySelector>
            </ChildDiv>
            <ChildDiv>
                <AirportSelector
                    country={country as string}
                    onSelectionUpdate={updateAirport}
                ></AirportSelector>
            </ChildDiv>
            <ChildDiv>
                <TerminalSelector
                    airport={airport as string}
                    onSelectionUpdate={updateTerminal}
                />
            </ChildDiv>
            <ChildDiv>
                <DateTimePicker
                    startTime={getStartDate()}
                    endTime={getEndDate()}
                    onPick={onTimeSelected}
                    maxDates={10}
                    minInterval={5}
                />
            </ChildDiv>
        </ParentDiv>
    )
}