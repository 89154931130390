import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import PERMISSIONS from "../../permissions/Permissions";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";
export const Navlinks = () => {
    const { user } = useAuth() as AuthContextType;
    const location = useLocation();
    return (
        <Navbar bg="light" expand="md">
            <Container>
                <Navbar.Brand>
                    <span className="brand-logo">
                        <img src="android-chrome-512x512.png" alt="✈"></img>
                    </span>
                    <Link className="navbar-brand" to={location.pathname}>
                       trypp: {location.pathname}
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        {!user?.username &&
                            <Nav.Link href={'/sign-in'}>
                                Login
                            </Nav.Link>
                        }
                        {(user?.permissions || []).includes(PERMISSIONS.CAN_VIEW_PROFILE) &&
                            <Nav.Link className="nav-link" href={'/queue-checker'}>
                                QueueChecker
                            </Nav.Link>
                        }
                        {(user?.permissions || []).includes(PERMISSIONS.CAN_VIEW_PROFILE) &&
                            <Nav.Link className="nav-link" href={'/user-report'}>
                                Report
                            </Nav.Link>
                        }
                        {(user?.permissions || []).includes(PERMISSIONS.CAN_VIEW_DASHBOARD) &&
                            <Nav.Link className="nav-link" href={'/dashboard'}>
                                Dashboard
                            </Nav.Link>
                        }
                        {(user?.permissions || []).includes(PERMISSIONS.CAN_VIEW_DASHBOARD) &&
                            <Nav.Link className="nav-link" href={'/terminals'}>
                                Terminals
                            </Nav.Link>
                        }
                        {user?.username &&
                            <NavDropdown title={user?.username} id="basic-nav-dropdown">
                                <NavDropdown.Item href={'/profile'}>Profile</NavDropdown.Item>
                                <NavDropdown.Item href={'/sign-out'}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
