import React, { useEffect, useState } from "react";
import { getTerminalAlias } from "../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";
import ComboBoxComponent from "./combo-box.component";

interface TerminalSelectorProps {
    onSelectionUpdate: Function
    airport: string
}

export function TerminalSelector({ airport, onSelectionUpdate }: TerminalSelectorProps) {
    let [terminals, setTerminals] = useState<any[]>([]);
    let { user } = useAuth() as AuthContextType;
    useEffect(() => {
        async function loadTerminals(airport: string) {
            let terminalNames = await getTerminalAlias(user.user.dbUser.appToken, airport);
            let terminals = terminalNames.map((t: string) => {
                return { id: t, value: t };
            });
            setTerminals(terminals);
        }
        loadTerminals(airport);
    }, [airport, user]);

    return (
        <ComboBoxComponent
            data={terminals}
            onSelectionUpdate={onSelectionUpdate}
            listLength={10}
            defaultMessage="Please select a terminal"
        />
    )
}