import React, { useEffect, useState } from "react";
import { getSchedules } from "../../api/api-wrapper";
import { AuthContextType, useAuth } from "../../provider/AuthProvider";
import Table, { TableStyles } from "../common/table.component";
const columns = [
    {
        Header: 'Airport',
        accessor: 'arr_iata'
    }, {
        Header: 'Airline',
        accessor: 'airline_iata'
    }, {
        Header: 'Flight #',
        accessor: 'flight_iata'
    }, {
        Header: 'Departure Time',
        accessor: 'dep_time',
        type: 'Date'
    }, {
        Header: 'Terminal',
        accessor: 'dep_terminal'
    }, {
        Header: 'Status',
        accessor: 'status'
    }
];

interface SchedulesProps {
    airport: string;
    terminal: string|null;
    setTerminals?: Function;
}

function Schedules({airport, terminal, setTerminals}: SchedulesProps) {
    let [ schedules, setSchedules ] = useState<any[]>([]);
    let [ currentAirport, setCurrentAirport ] = useState<string>();
    let [ currentTerminal, setCurrentTerminal] = useState<string|null>();
    let { user } = useAuth() as AuthContextType;
    useEffect(()=> {
        function updateTerminals(data: any[]) {
            if (!setTerminals) {
                return;
            }
            let terminals: any[] = [];
            data.forEach((s: any) => {
                let terminalName = s.dep_terminal;
                let existing = terminals.find((t: any) => t.value === terminalName);
                if (!existing) {
                    terminals.push({
                        id: terminalName,
                        value: terminalName
                    })
                }
            });
            terminals.sort((a,b) => {
                return a.value.localeCompare(b.value)
            })
            terminals.push({id:null, value:"All"})
            setTerminals(terminals);
        }
        async function loadData() {
            if (!airport || (airport === currentAirport && terminal === currentTerminal)) {
                return;
            }
            setCurrentAirport(airport);
            setCurrentTerminal(terminal);
            let data : any[] = await getSchedules(user.user.dbUser.appToken, airport);
            updateTerminals(data);
            if (terminal) {
                data = data.filter(row => row.dep_terminal === terminal);
            }
            setSchedules(data);
        }
        loadData();
    }, [ user, schedules, airport, terminal, currentAirport, currentTerminal, setTerminals ]);

    return(
        <TableStyles>
            <Table columns={columns} data={schedules} defaultPageSize={10} initialPage={0}/>
        </TableStyles>
    );
}

export default Schedules;