import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Table } from 'react-bootstrap'

export const TableStyles = styled.div`
    padding: 1rem;

    table {
        width: 100%;
        border-spacing: 0;
        border: 1px solid black;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
        float: center;
        display: grid;
        margin: 0.1rem 0rem 0.1rem 0rem;
    }
    .pagination button,
    .pagination span,
    .pagination select ,
    .pagination input {
        margin-left: 10px;
        margin-right: 10px;
    }
    .pagination-controls-buttons {
        grid-column: 1;
        grid-row: 1;
    }
    .pagination-controls-page-info {
        grid-column: 2;
        grid-row: 1;
    }
    .pagination-controls-page-selector {
        grid-column: 3;
        grid-row: 1;
    }
    @media(max-width: 900px) {
        .pagination button,
        .pagination span,
        .pagination select ,
        .pagination input {
            margin-left: 2px;
            margin-right: 2px;
        }
        .pagination-controls-page-info {
            grid-column: 1;
            grid-row: 2;
            margin: 1rem 0rem 1rem 0rem;
        }
        .pagination-controls-page-selector {
            grid-column: 1;
            grid-row: 3;
        }
    }
    @media(max-width: 300px) {
        .pagination button {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
`

interface ColumnType {
    Header: string;
    accessor: string;
    customDisplay?: Function,
    type?: string
}

interface TableProps {
    columns: Array<ColumnType>;
    data: Array<{ [accessor: string]: object; }>;
    defaultPageSize: number;
    initialPage: number;
    hidePageControls?: boolean;
}

export default function DataTable({ 
        columns, 
        data, 
        defaultPageSize=10, 
        initialPage=0,
        hidePageControls=false
    }: TableProps) {
    let [currentPage, setCurrentPage] = useState<number>(initialPage);
    let [pageSize, setPageSize] = useState<number>(defaultPageSize);
    function canPreviousPage() {
        return currentPage > 0;
    }
    function canNextPage() {
        return (currentPage + 1) * pageSize < data.length;
    }
    function getHeaders() {
        let headers =  [];
        for (let column of columns) {
            headers.push(
                <th key={`head${column.accessor}`}>{column.Header}</th>
            )
        };
        return headers;
    }
    function getRows() {
        let rows = [];
        let selectedData = data.slice(currentPage*pageSize, 1+ (currentPage+1)*pageSize);
        let index = 0;
        for (let datum of selectedData) {
            let row = [];
            for (let column of columns) {
                let innerHtml;
                let value : any = datum[column.accessor];
                if (column.customDisplay) {
                    innerHtml = column.customDisplay(value, datum);
                } else {
                    if (column.type === "Date") {
                        value = new Date(value).toLocaleString();
                    }
                    innerHtml = value as unknown as string;
                }
                let cell = <td key={`${index}${column.accessor}`}>{innerHtml}</td>
                row.push(cell);
            }
            rows.push(<tr key={index}>{row}</tr>);
            index++;
        }
        return rows;
    }
    function gotoPage(pageIndex: number) {
        setCurrentPage(pageIndex);
    }
    function previousPage() {
        setCurrentPage(currentPage - 1);
    }
    function nextPage() {
        setCurrentPage(currentPage + 1);
    }
    function getPageCount() {
        return Math.ceil(data.length/pageSize);
    }
    return (
        <div>
            <Table striped="columns">
            <thead>
                <tr>
                    {getHeaders()}
                </tr>
            </thead>
            <tbody>
                {getRows()}
            </tbody>
            </Table>
            {
                !hidePageControls &&
                <div className="pagination">
                    <div className="pagination-controls-buttons">
                        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage()}>
                            {'<<'}
                        </Button>{' '}
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage()}>
                            {'<'}
                        </Button>{' '}
                        <Button onClick={() => nextPage()} disabled={!canNextPage()}>
                            {'>'}
                        </Button>{' '}
                        <Button onClick={() => gotoPage(getPageCount()-1)} disabled={!canNextPage()}>
                            {'>>'}
                        </Button>{' '}
                    </div>
                    <div className="pagination-controls-page-info">
                        <span>
                            Page{' '}
                            <strong>
                                {currentPage+1} of {getPageCount()}
                            </strong>{' '}
                        </span>
                        <span>
                            | Go to page:{' '}
                        </span>
                    </div>
                    <div className="pagination-controls-page-selector">
                        <span>
                            <input
                                type="number"
                                defaultValue={currentPage+1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </span>{' '}
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            }    
        </div>
      );
}
