import React, { useEffect, useState } from 'react'
import AirportSelector from './airports.component';
import CountrySelector from './countries.component';
import styled from 'styled-components'
import Schedules from './schedules.component';
import { getLocation } from '../../api/api-wrapper';
import ComboBoxComponent from '../common/combo-box.component';

const ParentDiv = styled.div`
  & {
    width: 100%;
  }
`;

const ChildDiv = styled.div`
  & {
    display: inline-block;
    vertical-align: text-top;
    margin: 0 auto;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
`;


export default function Dashboard(props: any) {
    let [ country, setCountry ] = useState<string>();
    let [ airport, setAirport ] = useState<string>();
    let [ terminals, setTerminals ] = useState<any[]>([]);
    let [ terminal, setTerminal ] = useState<string|null>(null);
    useEffect(() => {
        async function loadDefaultCountry() {
            if (!country) {
                let defaultCountry = await getLocation();
                setCountry(defaultCountry.alpha_2);
            }
        }
        loadDefaultCountry();
    }, [country, airport])
    function updateCountry(country: string) {
        setCountry(country);
    }
    function updateAirport(airport: string) {
        setAirport(airport);
    }
    function updateTerminals(terminals: any[]) {
        setTerminals(terminals);
        let existingTerminal = null;
        if (terminal) {
            existingTerminal = terminals.find(t => t.id === terminal);
        }
        setTerminal(existingTerminal?.id);
    }
    return (

        <div className="page-wrapper">
            <div className="page-inner">
                <div>
                    <h3>Dashboard</h3>
                        <ParentDiv>
                            <ChildDiv>
                                <CountrySelector
                                    defaultCountry={country as string}
                                    onSelectionUpdate={updateCountry}
                                ></CountrySelector>
                            </ChildDiv>
                            <ChildDiv>
                                <AirportSelector
                                    country={country as string}
                                    onSelectionUpdate={updateAirport}
                                ></AirportSelector>
                            </ChildDiv>
                            <ChildDiv>
                                <ComboBoxComponent
                                    data={terminals}
                                    defaultMessage="Please select a terminal"
                                    onSelectionUpdate={setTerminal}
                                    listLength={10}
                                />
                            </ChildDiv>
                        </ParentDiv>
                    <div>
                    </div>
                    <Schedules
                        airport={airport as string}
                        terminal={terminal}
                        setTerminals={updateTerminals}
                    />
                </div>
            </div>
        </div>
    )

}
