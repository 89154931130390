import React, {  useState } from 'react'
import styled from 'styled-components'
import {  getQueueWaitTime } from '../../api/api-wrapper';
import { AuthContextType, useAuth } from '../../provider/AuthProvider';
import { getDateParts } from '../../utils/date-time-functions';
import { FilightFilterSelector, FlightFilters } from './flight-filter-selector.component';

const MainDiv = styled.div`
    min-height:20rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .queue-time {
        padding: 5rem;
    }
    .queue-time-mins {
        font-size:2rem;
    }
    @media(max-width: 600px) {
        .queue-time {
            padding: 1rem;
        }
    }
`;

export default function QueueChecker(props: any) {
    let [queueTime, setQueueTime] = useState('unknown');
    let [filters, setFilters] = useState<FlightFilters>({});
    let { user } = useAuth() as AuthContextType;
    function onFilterUpdate(filters: FlightFilters) {
        setFilters(filters);
        if (!filters.airport || !filters.country || !filters.time) {
            return;
        }
        getQueueWaitTime(user.user.dbUser.appToken, filters.airport, filters.time.getTime(), filters.terminal || null)
            .then((data: any) => {
                let { mins } = data;
                setQueueTime(mins);
            }).catch((err)=>{
                console.error("Failed getting queue time", err, filters);
            });
    }
    function getDateDisplay() {
        if (!filters.time) {
            return;
        }
        let dateParts = getDateParts(filters.time);
        return (<div>
            <span>{dateParts.date}{dateParts.dateSuffix} </span>
            <span>{dateParts.monthName}, </span>
            <span>{dateParts.year} </span>
            <span>{dateParts.hourStr}:</span>
            <span>{dateParts.minStr} </span>
            <span>{dateParts.ampm}</span>
        </div>);
    }
    return (

        <div className="page-wrapper">
            <div className="page-inner">
                <div>
                    <h3>QueueChecker</h3>
                    <FilightFilterSelector
                        onSelectionUpdate={onFilterUpdate}
                    />
                    <MainDiv>

                        {filters.time? 
                            <h5 className='queue-time'>
                                <div>Your estimated queue time for </div>
                                <div>{filters.airport} airport, {filters.country}</div>
                                <div> at {getDateDisplay()} is </div>
                                <div><span className='queue-time-mins'>{queueTime}</span><span> minutes</span></div>
                            </h5>
                            :
                            <h5 className='queue-time'>Select a time to check estimated queue time</h5>
                        }
                        
                    </MainDiv>
                </div>
            </div>
        </div>
    )

}
