import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReportAPIParams } from "../../../api/api-wrapper";

const ReportFormStyles = styled.div`
    width:100%;
    padding: 0.1rem 1rem 1rem 1rem;
    .formContainer {
        display:grid;
        grid-template-columns: 50% 50%;
        with:100%;
        border: 1px solid lightgray;
        padding: 1rem;
    }
    .sectionContainer {
        display:grid;
        text-align:center;
        grid-template-columns: 30% 30% 20%;
        grid-gap: 10px;
        padding:0.5rem;

    }
`;

interface ReportSection {
    time: number
}
export interface ReportSections {
    entry_gate?: ReportSection;
    checkin?: ReportSection;
    security?: ReportSection;
    immigration?: ReportSection;
}
interface ReportFormProps {
    defaultReportSections: ReportSections
    reportParams: ReportAPIParams
    onReportSectionsUpdate: Function
}
export default function ReportForm({defaultReportSections, onReportSectionsUpdate, reportParams}: ReportFormProps) {
    let [reportSections, setReportSections] = useState<any>({});
    const reportSectionsConfig = [
        { name: "Entry gate queue", col: "entry_gate"},
        { name: "Checkin queue", col: "checkin"},
        { name: "Security", col: "security"},
        { name: "Immigration", col: "immigration"},
    ]

    useEffect(() => {
        setReportSections(defaultReportSections || {});
    }, [defaultReportSections])

    function updateReport(e: React.ChangeEvent<HTMLInputElement>) {
        let {id, value} = e.target;
        reportSections[id] = reportSections[id] || {};
        reportSections[id].time = +value;
        setReportSections(reportSections);
        onReportSectionsUpdate(reportSections);
    }

    function getReportSections() {
        let items: any[] = [];
        reportSectionsConfig.forEach(section => {
            items.push(
                <div key={section.name} className="sectionContainer">
                    <span style={{textAlign:"right"}}><label>{section.name}</label></span>
                    <span>
                        <input 
                            className="form-control" 
                            id={section.col} 
                            onChange={updateReport}
                            defaultValue={(((reportSections || {})as any)[section.col]||{}).time}
                        />
                    </span>
                    <span style={{textAlign:"left"}}>mins</span>
                </div>
            )
            
        })
        return items;
    }

    return (

        <ReportFormStyles>
            <div className="formContainer">
                {getReportSections()}
            </div>
        </ReportFormStyles>
    );
}