import Authorization from "./Authorization";
import Login from '../components/auth/login.component';
import SignUp from '../components/auth/signup.component';
import Dashboard from '../components/dashboard/dashboard.component';
import Profile from '../components/main/profile.component';
import ForgotPassword from '../components/auth/forgot-password.component';
import ResetEmailSent from '../components/auth/reset-email-sent.component';

import { Routes, Route } from 'react-router-dom';
import PERMISSIONS from "../permissions/Permissions";
import PageNotFound from "../components/main/pageNotFound.component";
import SignOut from "../components/auth/signout.component";
import QueueChecker from "../components/dashboard/queue-checker.component";
import PrivacyPolicy from "../components/main/privacy-policy";
import TermsOfService from "../components/main/tems-of-service";
import AuthEmail from "../components/auth/auth-email.component";
import React from "react";
import Terminals from "../components/dashboard/terminals.component";
import UserReport from "../components/dashboard/user-report/user-report.component";

const RoutePath = () => {
    return (
    <Routes>
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route
            element={<Authorization permissions={[PERMISSIONS.CAN_VIEW_DASHBOARD]} />}
        >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/terminals" element={<Terminals />} />
        </Route>
        <Route
            element={<Authorization permissions={[PERMISSIONS.CAN_VIEW_PROFILE]} />}
        >
            <Route path="/queue-checker" element={<QueueChecker />} />
            <Route path="/user-report" element={<UserReport />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/" element={<QueueChecker />} />
            <Route path="/sign-out" element={<SignOut />} />
        </Route>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-email-sent" element={<ResetEmailSent />} />
        <Route path="/auth-email" element={<AuthEmail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="*" element={<PageNotFound />} />
    </Routes>
    );
};

export default RoutePath;