import React from "react";
import Table from "../../common/table.component";

interface UserSchedulesProps {
    onSelectionUpdate: Function
    schedules: any[]
}

export default function UserSchedules({ onSelectionUpdate, schedules }: UserSchedulesProps) {
    const columns = [
        {
            Header: '',
            accessor: 'checked',
            customDisplay: (value: boolean, row: any) => {
                return (
                    <input type="radio" checked={value}
                        onClick={() => { onSelectionUpdate(row) }}
                        onChange={() => { }}
                    />
                );
            }
        },
        {
            Header: 'Airport',
            accessor: 'dep_iata'
        }, {
            Header: 'Airline',
            accessor: 'airline_iata'
        }, {
            Header: 'Flight #',
            accessor: 'flight_iata'
        }, {
            Header: 'Departure Time',
            accessor: 'dep_time',
            type: 'Date'
        }, {
            Header: 'Terminal',
            accessor: 'dep_terminal'
        }, {
            Header: 'Status',
            accessor: 'status'
        }
    ];

    return (
        <Table
            columns= { columns }
            data = { schedules }
            defaultPageSize = { 10}
            initialPage = { 0}
            hidePageControls = { true}
        />
    );
}